<template>
  <div class="projects">
    <div class="toprow">
      <img src="../assets/PlogoThumbnail.png"/>
      <h1>Project Gallery</h1>
    </div>
    <br>
    <div>
      <h2>Creative Projects</h2>
      <p>My stories are available at <a href='https://www.barnesandnoble.com/s/%22A.%20N.%20Radcliffe%22?Ntk=P_key_Contributor_List&Ns=P_Sales_Rank&Ntx=mode+matchall'>Barnes and Nobles</a> and <a href='https://www.amazon.com/dp/B0CSWYPD74?binding=paperback&ref=dbs_dp_sirpi'>Amazon</a>!</p>
      <p>If you click on the links above, it will take you to books.
      </p>
      <p>A huge shout out to the editors I have worked with!  Thank you!  You helped bring these stories to life.
      </p>
      <hr>
      <div class="projCard">
        <img class="ptileImag" src="@/assets/rivers2-mini.png"/>
        <ProjectTile
      v-bind:key="1"
      Ptitle="The Rivers Chronicles"
      Psum="The novel series following Emily Wood and her adventures hopping between her regular
      normal world and the magic kingdom of Rowayna.  She meets Antonio Rivers and
      mixes science & magic to create all kinds of extraordinary weapons and pirate ship modifications."
        />
      </div>
      <hr>
      <div class="projCard">
        <img class="ptileImag" src="@/assets/guesswho1-mini.png"/>
        <ProjectTile
        v-bind:key="2"
        Ptitle="Guess Who?"
        Psum="The novel series following Alice Gray, the police sketch artist, and her ghost partner
        Henry as they solve crimes by speaking to the ghosts of victims.  With her psychic abilities,
        she helps solve murders and gets asked by the FBI to help solve cold cases."
        />
      </div>
      <hr>
      <br><br>
      <div class="comson">
        <i>In the Works and Coming Soon</i>
      </div>
      <ProjectTile
      Ptitle="The Academy"
      Psum="Allison gets accepted into the most prestigious school, The Academy.
        The Academy is a private school owned by White Anchor Inc. and is situated
        on a private island.  The island is divided into three unique areas of focus,
        The School of Academics, The School of Art, and the School of Military
        Training.  Allison was intrigued by its unique education hierarchy that allows
        older students to teach and act as principals and is thrilled to attend.
        However, The Academy falls into chaos when one of the principals tries to take
        control of the island."
      />
    </div>
    <ProjectTile
    Ptitle="Story Book Hill"
    Psum="This novel series follows Wyllis Tanner, Emily Wood's best friend, and her
    journey as a magic user.  She joins the Story Book Hill Coven and tries to
    uncover the secrets of her ancestry."
    />
    <div>
      <h2>Technical Projects</h2>
      <p>This section represents some of the technical projects I have completed over 
        the years.  I have been programing since 2009, when I cut my teeth with Flash 
        Animation.  Since then, I have expanded my skill set to include working with SCADA 
        systems, automation for manufacturing, machine learning, web scraping, web design, AI 
        assistants, cloud computing, and building/managing API endpoints.
      </p>
      <p>
        Below are some of the projects listed on my github, <a href='https://github.com/A-rad'>A-rad</a>, 
        as well as some of my current works in progress.
      </p>
      <hr>
      <div class="projCard">
        <img class="ptileImag" src="@/assets/placeholder-pic.png"/>
        <ProjectTile
        Ptitle="Mavis - My Voice Activated AI Assistant"
        Psum="This is my experimental voice activated AI assistant that can perform basic tasks, 
        nothing crazy.  It can run basic google queries and report results, play my favorite radio 
        programs, execute any of my other applications, and connect with my API endpoints.  I gave 
        Mavis the ability to store and access memories and share those memories with a collective 
        'Hive Brain'.  The AI assistant program can launch multiple instances with different names. 
        Additionally, these AI assistants can recognize hostile users and share this with the collective."
        />
      </div>
      <hr>
      <div class="projCard">
        <img class="ptileImag" src="@/assets/placeholder-pic.png"/>
        <ProjectTile
        Ptitle="Inventory Management System w/ Barcode Scanner"
        Psum="This application lets users manage inventory using a sqlite database 
        that can store product information, location, and associated barcode.  The 
        GUI lets users scan a barcode and either add items or modify existing records."
        />
      </div>
      <hr>
      <div class="projCard">
        <img class="ptileImag" src="@/assets/placeholder-pic.png"/>
        <ProjectTile
        Ptitle="Automated SCADA"
        Psum="This mini workstation was designed and built for manufacturing process equipment. 
        It uses a Raspberry Pi and provides the users with real time data from various 
        devices, including data from both analog and digital devices.  It can accommodate 
        serial and ASCII communication."
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTile from '@/components/ProjectTile.vue'

export default {
  components: {
    ProjectTile
  }
}
</script>

<style scoped>
* {
  flex-basis:"100%"
}
.toprow{
  display: flex;
  align-items: center;
  text-align: left;
  max-height:90px;
}
h1 {
  display: flex;
  font-size:40px;
  padding:10px 10px;
  max-height:90px;
}
.toprow img {
  display: flex;
  width: 87px;
  height:87px;
}
h2 {
  text-align: left;
}
.projCard {
  box-sizing: border-box;
  justify-content: space-around;
  display: flex;
  align-items: center;
  text-align: left;
}
p {
  text-align: left;
}
.comson {
  text-align: left;
  font-size: 20px;
}
</style>
