<template>
  <div class="home">
    <div class="toprow">
      <img src="../assets/PlogoThumbnail.png"/>
      <h1>The Empire News</h1>
    </div>
    <div class="news-column">
      <p>Welcome to the Empire!  Empress Peach welcomes all visitors and hopes you
        enjoy the gallery of projects on display for the public.  As content is added,
        projects are completed, and new projects are started, the gallery will be
        updated accordingly.  Please visit the gallery often to see the latest works.
      </p>
      <br>
      <i class="comson">People of the Empire!</i>
      <p>An Empire wouldn't exist without the separate states (aka people) under the 
        supreme rule of Empress Peach (Me).  To celebrate the many unique faces of the 
        Empire, I will be adding a <i>People of the Empire</i> section with personalized 
        pages for each of my esteemed delegates.
      </p>
      <br>
      <i class="comson">Potential Collaborations</i>
      <p>If you have enjoyed your visit and like what you see, feel free to reach out.  
        Empress Peach can be reached on Instagram, Facebook, and other social networking 
        sites.
      </p>
    </div>
  </div>
</template>

<style scoped>
* {
  flex-basis:"100%"
}
.toprow{
  display: flex;
  align-items: center;
  text-align: left;
  max-height:90px;
}
h1 {
  display: flex;
  font-size:40px;
  padding:10px 10px;
  max-height:90px;
}
.toprow img {
  display: flex;
  width: 87px;
  height:87px;
}
.news-column {
  text-align: left;
}

.comson {
  text-align: left;
  font-size: 20px;
}
</style>