<template>
  <div>
    <div id="nav" class="topnav">
      <router-link to="/">Home</router-link>
      <h3>EmpressPeach's Empire</h3>
      <router-link to="/projects">Projects</router-link>
    </div>
    <hr>
    <section>
      <router-view/>
    </section>
    <hr>
    <footer id="footer">
      <Footheader/>
    </footer>
  </div>
</template>

<script>
import Footheader from '@/components/Footheader.vue'

export default {
  components: {
    Footheader
  }
}
</script>

<style>
#app {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  justify-content: space-around;
}

.topnav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  background: #1b7e1b;
}

#nav a {
  font-weight: bold;
  color: #eafbea;
}
#nav h3 {
  color: white;
  padding: 10px, 10px;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#footer{
  color: white;
  padding: 10px, 10px;
  background: #1b7e1b;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}
</style>
