<template>
    <div class="projTile">
        <h3>{{ Ptitle }}</h3>
        <p>{{Psum}}</p>
    </div>
</template>

<script>
export default {
  name: 'ProjectTile',
  props: {
    Ptitle: String,
    Psum: String
  }
}
</script>

<style scoped>
* {
    padding: 10px 10px;
}
.projTile{
    box-sizing: border-box;
    justify-content: space-around;
    display: flex;
    align-items: center;
    text-align: left;
}
h3 {
    min-width:110px;
}

</style>
